import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/german',
    name: 'german',
    component: Home,
    children: [
      {
        path: 'cv',
        name: 'german.cv',
        component: () => import(/* webpackChunkName: "german" */ '../components/GermanSkills.vue'),
      },
      {
        path: 'timeline',
        name: 'german.timeline',
        component: () => import(/* webpackChunkName: "german" */ '../components/GermanSkills.vue'),
      },
    ],
  },
  {
    path: '/english',
    name: 'english',
    component: Home,
    children: [
      {
        path: 'cv',
        name: 'english.cv',
        component: () => import(/* webpackChunkName: "english" */ '../components/EnglishSkills.vue'),
      },
      {
        path: 'timeline',
        name: 'english.timeline',
        component: () => import(/* webpackChunkName: "english" */ '../components/EnglishSkills.vue'),
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const scrollIntoView = (to: string): void => {
  const routeArray = ['german.cv', 'german.timeline', 'english.cv', 'english.timeline'];

  if (routeArray.includes(to)) {
    setTimeout(() => document.getElementById('theShowDiv')?.scrollIntoView(true), 200);
  }
};

router.beforeEach((to, from, next) => {
  scrollIntoView(to.name || '');
  next();
});

export default router;
