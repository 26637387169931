import Vue from 'vue';
import VueParticles from 'vue-particles';
import VueTypedJs from 'vue-typed-js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(VueParticles);
Vue.use(VueTypedJs);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  created() {
    AOS.init({
      duration: 1000,
    });
  },
}).$mount('#app');
